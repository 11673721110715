import logo from "../assets/images/logo.png";
import { AiFillCaretDown } from "react-icons/ai";
import { Link, useLocation } from "react-router-dom";
import { Navbar, Container, Nav, Modal, Form, Image, Row, Col, Button } from "react-bootstrap";
import { useState, useEffect } from "react";

export const Header = () => {
  const location = useLocation();
  const { pathname } = location;
  const [showModal, setShowModal] = useState(false)



  /***** ***** ***** ***** *****/
  /* Refer Person - Begin
  /***** ***** ***** ***** *****/
  const [clientRef, setClientRef] = useState('');
  const getQueryParams = (qs) => {
    qs = qs.split('+').join(' ');

    var params = {},
      tokens,
      re = /[?&]?([^=]+)=([^&]*)/g;

    while (tokens = re.exec(qs)) {
      params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
    }

    return params;
  }
  useEffect(() => {
    var query = getQueryParams(document.location.search);
    if (query.ref != 'undefined' && query.ref != null) {
      const cRef = '?ref=' + query.ref;
      setClientRef(cRef);
      localStorage.setItem('00ref', cRef);
    } else {
      const cRef = localStorage.getItem('00ref');
      if (cRef != null) {
        setClientRef(cRef);
      }
    }
  }, [])
  /***** ***** ***** ***** *****/
  /* Refer Person - End
  /***** ***** ***** ***** *****/

  const showPopupmodal = () => {
    return (

      <Modal
        show={showModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        className="popupModal"
      >
        <Modal.Body>
          <Row>
            <Col md={11} className="text-center">
              <Image src={require('./../assets/images/logo.png')} width={200} />
            </Col>
            <Col md={1}>
              <button type="button" class="btn-close" ></button>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="text-body">
                <h3>Welcome to the Future of <br />
                  Real Estate Lead Generation!</h3>

                <h2>Join the Skipify.ai Waitlist!</h2>
                <p>Limited Time Offer: Be among the first to experience Skipify.ai. <br />
                  Fill out the information below and be notified when we launch for exclusive offers</p>


                <Row>
                  <Col md={10} className="mx-auto">
                    <Row>
                      <Col md={6}>
                        <Form.Group className="my-3 password">
                          <Form.Label>First Name *</Form.Label>
                          <Form.Control
                            type='text'
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="my-3 password">
                          <Form.Label>Last Name *</Form.Label>
                          <Form.Control
                            type='text'
                          />
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <Form.Group className="mb-3 password">
                          <Form.Label>Email *</Form.Label>
                          <Form.Control
                            type='email'
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3 password">
                          <Form.Label>Phone Number *</Form.Label>
                          <Form.Control
                            type='email'
                          />
                        </Form.Group>
                      </Col>
                      <Col md={12}>
                        <Form.Check // prettier-ignore
                          type="checkbox"
                          label={<p>I agree to receive marketing emails, phone calls, and SMS from Skipify.ai. For more information
                            visit our <Link to="/terms">Terms and Conditions</Link>. We respect your privacy and promise not to spam you.</p>}
                        />
                      </Col>

                      <Col md={5} className="mx-auto mb-4">
                        <Button >Apply For Waitlist</Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Modal.Body>

      </Modal>
    )
  }

  const [expanded, setExpanded] = useState(false);

  const closeNavbar = () => setExpanded(false);


  return (
    <>
      {showPopupmodal()}
      <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary" expanded={expanded}>
        <Container>
          {pathname === '/' ?
            <a href={`${process.env.REACT_APP_URL}`} className="navbar-brand"><img src={logo} alt="skipify logo" width={200} height={50} /></a>
            :
            <Link to="/" className="navbar-brand"><img src={logo} alt="skipify logo" width={200} height={50} onClick={() => setExpanded(false)} /></Link>
          }
          <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setExpanded(!expanded)} />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="right-nav menu_nav" onClick={closeNavbar}>
              <li><Link className={` nav-link ${pathname === '/about-us' ? 'active' : ''}`} to="/about-us"> About Us </Link></li>

              <li className="dropdown">
                <span className="nav-link" >Our Data <AiFillCaretDown /></span>
                <ul className="navStep2">
                  <li><Link to='/pre-foreclosure' className="dropdown-item">Pre-Foreclosure & Propensity</Link></li>
                </ul>
              </li>
              <li><Link className={` nav-link ${pathname === '/pricing-plan' ? 'active' : ''}`} to="/pricing-plan">Pricing</Link></li>
              <li><Link className={` nav-link ${pathname === '/blogs' ? 'active' : ''}`} to="/blogs">Blogs</Link></li>
              <li><Link className={` nav-link ${pathname === '/skipify-u' ? 'active' : ''}`} to="/skipify-u">Skipify U</Link></li>
              <li><Link className={` nav-link ${pathname === '/affiliate' ? 'active' : ''}`} to="/affiliate">Affiliate</Link></li>
              {/* <li><a href="https://plego.com/" target="_blank" className="nav-link">Affiliate</a></li> */}
              <li><Link className={` nav-link ${pathname === '/contact-us' ? 'active' : ''}`} to="/contact-us">Contact </Link></li>
            </Nav>
            <Nav className="right-nav desktop" onClick={closeNavbar}>
              <li className="d-flex justify-content-center align-items-center"><a href={`${process.env.REACT_APP_USER_SIDE_URL}${clientRef}`} className="nav-link link-outline-primary">Log In</a></li>
              <li className="d-flex justify-content-center align-items-center"><Link to='/pricing-plan' className="nav-link linkprimary">Create Account</Link></li>
              {/* <li><a href={`${process.env.REACT_APP_USER_SIDE_URL}/register${clientRef}`} className="nav-link btnPrimary">Create Free Account</a></li> */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Header
